<template>
  <div class="members-profile" v-if="questionnaires">
    <v-container class="first-part">
      <Breadcrumbs v-if="member" />

      <v-row>
        <v-col class="d-flex pb-0">
          <span class="icomoon icon-patient"></span>
          <h1 class="d-flex ml-2 page-title">
            {{ $t("profile") }}
          </h1>
        </v-col>
      </v-row>

      <v-row
        :class="`basic-info mt-12 pl-2 ${isLifeChamps ? 'mb-8' : 'mb-16'} `"
        v-if="member"
      >
        <template v-if="!isLifeChamps">
          <v-col class="card pt-0" cols="1">
            <p class="card-title mb-2">{{ $t("id") }}</p>
            <p class="card-body"></p>
          </v-col>
          <v-col class="card pt-0" cols="2">
            <p class="card-title mb-2">{{ $t("insurance_number") }}</p>
            <p class="card-body">
              {{ this.member.insurance_number }}
            </p>
          </v-col>
          <v-col class="card pt-0" cols="1">
            <p class="card-title mb-2">{{ $t("gender") }}</p>
            <p class="card-body">
              {{ this.member.gender == 1 ? $t("male") : "" }}
            </p>
            <p class="card-body">
              {{ this.member.gender == 2 ? $t("female") : "" }}
            </p>
          </v-col>
          <v-col class="card pt-0" cols="1">
            <p class="card-title mb-2">{{ $t("age") }}</p>
            <p class="card-body">{{ this.member.age }}</p>
          </v-col>
        </template>
        <v-col class="card pt-0" cols="7">
          <v-btn
            @click="toggleIntervened"
            hide-details
            class="
              ma-auto
              pt-0
              pb-0
              text-uppercase
              intervened
              d-flex
              align-center
              text-center
              float-right
              bg-theme-primary
            "
            :class="{ 'mark-as-intervened': !isIntervened }"
            v-if="member"
          >
            <v-icon v-if="isIntervened">mdi-checkbox-marked</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            <span class="label">
              {{ isIntervened ? $t("intervened") : $t("mark_as_intervened") }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="medical-info-container">
      <v-container>
        <v-tabs v-model="tab" align-with-title class="mb-8">
          <v-tabs-slider
            :color="
              $store.getters['auth/user']
                ? $store.getters['auth/user'].theme_config.primary_color
                : ''
            "
          ></v-tabs-slider>

          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="pl-5">
          <!-- the next line is commented for demo purpose and will uncommented after using dynamic component -->
          <!-- <v-skeleton-loader
            v-if="!questionnaire.questionnaire_id"
            type="card-heading, image"
          ></v-skeleton-loader> -->
          <v-tab-item v-for="item in items" :key="item">
            <v-container v-if="tab == 0">
              <v-row class="programs mb-14" v-if="showEdmontonSymptoms">
                <v-col cols="12">
                  <div class="h2 mb-5 pl-1 title">programs</div>
                </v-col>
                <v-col>
                  <v-card flat>
                    <p>adhera for cancer</p>
                    <p class="mb-0">
                      <strong>2</strong> months <strong>54</strong>
                      {{ $t("days") }}
                    </p>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card flat>
                    <p>diabetes</p>
                    <p class="mb-0"><strong>54</strong> {{ $t("days") }}</p>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card flat>
                    <p>smoking cessation</p>
                    <p class="mb-0"><strong>12</strong> {{ $t("days") }}</p>
                  </v-card>
                </v-col>
              </v-row>
              <!-- the next lines is just for demo purpose and will be removed -->
              <QuestionnaireCardsContainer
                v-if="!showEdmontonSymptoms & !isLifeChamps"
                :questionnaires="questionnaires['non-relapse-report']"
                title="patient_reported_outcome"
              />
              <QuestionnaireCardsContainer
                v-if="!showEdmontonSymptoms & !isLifeChamps"
                :questionnaires="questionnaires['relapse-report']"
                title="relapse_report"
                class="relapse-report"
              />

              <p
                v-if="
                  !showEdmontonSymptoms &
                  !isLifeChamps &
                  (questionnaires['relapse-report'].length == 0) &
                  (questionnaires['non-relapse-report'].length == 0)
                "
                class="text-center text-capitalize mt-10 title no-data-available"
              >
                {{$t("no_data_available")}}!
              </p>

              <PsychometricReports
                v-if="!showEdmontonSymptoms & isLifeChamps"
                :reportedDate="reportedDate"
                :questionnaire="questionnaire"
              />
              <EdmontonSymptoms
                v-if="showEdmontonSymptoms"
                :reportedDate="reportedDate"
                :questionnaire="questionnaire"
              />

              <!-- <component
                :is="questionnaireType[questionnaire.questionnaire_title]"
                :questionnaire="questionnaire"
                :reportedDate="reportedDate"
              /> -->
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

const components = {
  Breadcrumbs: () => import("@/components/Breadcrumbs.vue"),
  EdmontonSymptoms: () =>
    import("@/components/views/dashboard/members/id/EdmontonSymptoms.vue"),
  PsychometricReports: () =>
    import("@/components/views/dashboard/members/id/PsychometricReports.vue"),
  PatientReportedOutcome: () =>
    import(
      "@/components/views/dashboard/members/id/PatientReportedOutcome.vue"
    ),
  QuestionnaireCardsContainer: () =>
    import(
      "@/components/views/dashboard/members/id/QuestionnaireCardsContainer.vue"
    ),
};

export default {
  data() {
    return {
      tab: null,
      items: [
        this.$t("health"),
        this.$t("patient profile"),
        this.$t("history"),
      ],
      questionnaire: {},
      reportedDate: null,
      questionnaireType: {
        "Symptoms report": "EdmontonSymptoms",
      },
      questionnaireComponentLoading: true,
      showEdmontonSymptoms: false,
      intervened: false,
      questionnaires: null,
    };
  },

  components,

  computed: {
    isLifeChamps: function () {
      return (
        this.$store.getters["auth/user"]?.theme_config.primary_color ==
        "#3452FF"
      );
    },
    isIntervened: {
      get() {
        return this.member && this.member.is_intervened;
      },
      set(val) {
        this.member.is_intervened = val;
      },
    },
    member: function () {
      return this.$store.getters["members/member"];
    },
  },

  created() {
    this.getMember();
    this.getQuestionnaires();
  },

  methods: {
    ...mapActions({
      getMember: "members/getMember",
    }),
    getQuestionnaires() {
      axios
        .get(`/dashboard/questionnaire-summary/${this.$route.params.id}`)
        .then((res) => (this.questionnaires = res?.data?.data))
        .catch((err) => console.log(err));
    },
    toggleIntervened() {
      axios
        .post("/dashboard/users/update-intervened", {
          user_id: this.$route.params.id,
          intervened: !this.isIntervened,
        })
        .then((res) => {
          this.isIntervened = !this.isIntervened;
          this.getQuestionnaires();
          this.$toasted.message(this.$t("member_intervened_succesfully"));
        })
        .catch((err) => this.$toasted.error(err?.response?.data?.data[0]));
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/views/dashboard/members/id.scss">
</style>